import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDBOFPaHxZ6ubpaAXpNtU54M8AF6I_oBEA",
  authDomain: "jamjoom-registration.firebaseapp.com",
  projectId: "jamjoom-registration",
  storageBucket: "jamjoom-registration.appspot.com",
  messagingSenderId: "1082475015292",
  appId: "1:1082475015292:web:aba770781a0b0100842df9"
};

// Initialize Firebase
const appDB = initializeApp(firebaseConfig);

export default getFirestore(appDB);